<template>
  <div class="video-container">
    <header class="header">
      <van-nav-bar
        v-bind:title="state.myVideoListTag ? '我的视频' : '视频列表'"
        left-arrow
        fixed
        safe-area-inset-top
        @click-left="comback"
      >
        <template #right>
          <van-icon
            name="search"
            size="24"
            color="#333333"
            @click="loadSearchVideo"
          />
        </template>
      </van-nav-bar>
      <div class="placeHolder"></div>
      <div class="lv-one" v-if="state.navBarActive==='videoList'&&state.showLvOne">
        <div class="lv-one-type">
          <div :class="state.topType===1?'bc-color':''" @click="handleChoiceLvOne(1)">企业视频</div>
          <div :class="state.topType===2?'bc-color':''" @click="handleChoiceLvOne(2)">推荐视频</div>
        </div>
      </div>
      <div class="twotypeselect" v-show="!state.showAdd&&state.navBarActive==='videoList'">
        <div class="articleType-all">
          <van-tabs
              @click="loadAllType"
              v-model="state.activeTabIndex"
              swipe-threshold="1"
              color="#FF6E01"
              title-active-color="#FF6E01"
              ref="vanTabsInstanceRef"
          >
<!--            <van-tab v-if="loadNiceChoice" title="精选"></van-tab>-->
            <van-tab
                v-for="it in state.typeList"
                :key="it.categoryPrimaryId"
                :title="it.materialCategoryName"
                :name="it.categoryPrimaryId"
            ></van-tab>
            <!--            <van-tab v-if="showMore" title="更多"></van-tab>-->
          </van-tabs>
        </div>
        <div class="more-type" @click="handleMoreType">更多</div>
      </div>
<!--      <div class="videoType-all" v-show="!state.showAdd&&state.navBarActive==='videoList'">-->
<!--        <van-tabs-->
<!--          @click="loadAllType"-->
<!--          swipe-threshold="4"-->
<!--          color="#FF6E01"-->
<!--          title-active-color="#FF6E01"-->
<!--          v-model="state.activeTabIndex"-->
<!--          ref="vanTabsInstanceRef"-->
<!--        >-->
<!--          <van-tab-->
<!--            v-for="it in state.typeList"-->
<!--            :key="it.typeId"-->
<!--            :name="it.typeId"-->
<!--            :title="it.typeName"-->
<!--          ></van-tab>-->
<!--        </van-tabs>-->
<!--      </div>-->
    </header>
    <article id="article">
      <van-list
        v-model:loading="state.loading"
        :finished="state.finished"
        finished-text="没有更多了"
        @load="throttleOnLoadMore"
      >
        <!--        <van-list-->
        <!--                v-model:loading="state.loading"-->
        <!--                :finished="state.finished"-->
        <!--                finished-text="没有更多了"-->
        <!--                @load="onLoadMore"-->
        <!--        >-->
        <van-pull-refresh
          @refresh="onRefresh(state.categoryIds)"
          v-model="state.refreshing"
        >
          <video-type
            :videoList="state.videoList"
            :myVideoListTag="state.myVideoListTag"
            @delete_my_video="deleteMyVideo"
            :addPoster="state.addPoster"
          ></video-type>
        </van-pull-refresh>
      </van-list>
    </article>
    <footer class="footer-tab-bar">
      <div class="add_video" @click="handleCreateVideo">+</div>
      <van-tabbar v-model="state.navBarActive" @change="onNavBarChange">
        <van-tabbar-item name="videoList" icon="apps-o"
          >视频列表</van-tabbar-item
        >
        <van-tabbar-item name="myVideoList" icon="play-circle-o"
          >我的视频</van-tabbar-item
        >
      </van-tabbar>
    </footer>
    <!-- 遮罩层 -->
    <van-overlay :show="state.showType">
      <div class="wrapper" @click.stop>
        <div class="select-like-type">
          <i class="cancel" @click="cancelSelectType"></i>
          <p class="title">选择你喜欢的类别</p>
          <div class="grid-type">
            <div
              :class="[
                'grid-type-item',
                state.box.includes(it) ? 'bgColor' : '',
              ]"
              v-for="it in state.moreTypeList"
              :key="it.categoryPrimaryId"
              @click="handleChoice(it)"
            >
              {{ it.materialCategoryName }}
            </div>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
          </div>
          <div class="confirm" @click="loadConfirm">确认</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { useRouter,useRoute } from "vue-router";
import { useStore } from "vuex";
import { Toast } from 'vant';

import { throttle } from "../../utils/commonUtil";
import videoType from "../../views/video/type/type.vue";
import { getVideoList, getMyVideoList } from "../../api/video";
import { getMerchantMaterialFullCategory } from "../../api/articleManagement";
import {getMerchantManagementScope} from "../../api/huokeArticle";
import {flutterShowToast} from "@/utils/appChannel";

export default {
  name:"videoList",
  components: {
    videoType,
  },
  activated(){
    document.getElementById('article').scrollTop = Number(sessionStorage.getItem('scrollTop'))
    // console.log("activated缓存视频-----------",sessionStorage.getItem('scrollTop'))
  },
  deactivated(){
    let scrollTop = document.getElementById('article').scrollTop
    sessionStorage.setItem('scrollTop',scrollTop)
    // console.log("deactivated缓存视频-----------",scrollTop)
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute()
    console.log("===store==", store.getters.myVideoListTag);
    const state = reactive({
      topType:1,
      showLvOne:false,
      addPoster:route.params.addPoster==='undefined'?'':route.params.addPoster,
      fromPoster:route.params.fromPoster==='undefined'?'':route.params.fromPoster,
      loading: false,
      finished: false,
      size: 5,
      currentPage: 1,
      videoList: [],
      refreshing: false,
      showChoice: 1,
      activeTabIndex: 0,
      navBarActive: store.getters.myVideoListTag ? "myVideoList" : "videoList",
      myVideoListTag: store.getters.myVideoListTag,
      categoryIds: null,
      showType: false,
      box: [],
      moreTypeList: [
        { typeId: 1, typeName: "装修利器" },
        { typeId: 1, typeName: "装修利器" },
        { typeId: 2, typeName: "装修利器" },
        { typeId: 2, typeName: "装修利器" },
        { typeId: 2, typeName: "装修利器" },
        { typeId: 2, typeName: "装修利器" },
        { typeId: 2, typeName: "装修利器" },
        { typeId: 2, typeName: "装修利器" },
        { typeId: 2, typeName: "装修利器" },
        { typeId: 2, typeName: "装修利器" },
        { typeId: 2, typeName: "装修利器" },
        { typeId: 2, typeName: "装修利器" },
      ],
      typeList: [
        { categoryPrimaryId: 1, materialCategoryName: "精选" }
      ],
    });

    const getMerchantManagement = async () =>{
      let res = await getMerchantManagementScope()
      state.showLvOne = res
      console.log(res)
      if (!res){
        state.topType = 0
      }
      // loadArticleMoreType();
    }
    getMerchantManagement()
    const handleChoiceLvOne = (val) =>{
      state.topType = val
      // state.twoType = 0
      // state.current = 1
      // state.pages = 1
      // state.cases = []
      state.typeList = [
        { categoryPrimaryId: 1, materialCategoryName: "精选" }
      ];
      // state.typeList.push({ typeId: 0, typeName: "更多" });
      state.moreTypeList = [];
      getVideoMaterialFullCategory()
      getInitialVideoList()
    }
    //曲线解决初始化的时候van-list也会触发onLoad的问题
    var initGetVideoListTag = true;
    const getInitialVideoList = () => {
      Toast.loading({
        message: '加载中...',
        // forbidClick: true,
        duration:1000,
        loadingType: 'spinner',
      });
      state.myVideoListTag
        ? getMyVideoList({
            size: state.size,
            categoryIds: state.categoryIds,
          }).then((data) => {
            state.videoList = data.records;
            initGetVideoListTag = false;
            Toast.clear()
          })
        : getVideoList({
            size: state.size,
            categoryIds: state.categoryIds,
            materialType:state.topType,
          }).then((data) => {
            state.videoList = data.records;
            initGetVideoListTag = false;
            Toast.clear()
          });
    };
    getInitialVideoList();

    //van-tab切换时恢复默认参数
    const resetOnNavTabChange = () => {
      //当前页也恢复默认
      state.currentPage = 1;
      //上拉组件状态也恢复默认
      state.finished = false;
    }

    //加载分类
    const getVideoMaterialFullCategory = ()=>{
      getMerchantMaterialFullCategory({
        modeId:3,
        materialType:state.topType,
      }).then((data) => {
        let categoryList = data;
        // state.typeList.push(...categoryList.slice(0, 4));
        state.typeList.push(...categoryList);
        // state.typeList.push({ typeId: 0, typeName: "更多" });
        state.moreTypeList = categoryList;
      });
    }
    getVideoMaterialFullCategory()

    //搜索
    const loadSearchVideo = () => {
      router.push({
        path: "/video/searchVideo",
        query: { myVideoListTag: state.myVideoListTag ? 1 : 0 },
      });
    };

    //添加视频事件
    const handleCreateVideo = () => {
      // router.push({
      //   path: "/video/createVideo"
      // })
      router.push({
        path: "/video/selectCreateType"
      })
    }
    const handleMoreType = () =>{
      // state.categoryIds = '0'
      loadAllType(0)
    }

    const loadAllType = async (id) => {
      console.log(state.activeTabIndex);
      resetOnNavTabChange()
      //隐式转换为string. id为1是精选,精选是加载所有
      // state.categoryIds = id == 1? null : id + "";
      if(id===0){
        loadSelectType();
      }else {
        if(id===1){
          state.categoryIds = null
        }else {
          state.categoryIds = id + ""
        }
        let data = state.myVideoListTag
            ? await getMyVideoList({ categoryIds: state.categoryIds, })
            : await getVideoList({ categoryIds: state.categoryIds, materialType:state.topType });
        state.videoList = data.records;
      }
      console.log("===state.categoryIds===", state.categoryIds)
      //id为0是更多
      // if (state.categoryIds === "0") {
      //   loadSelectType();
      // } else {
      //   let data = state.myVideoListTag
      //     ? await getMyVideoList({ categoryIds: state.categoryIds })
      //     : await getVideoList({ categoryIds: state.categoryIds });
      //   state.videoList = data.records;
      // }
    };

    //刷新
    const onRefresh = async (ids) => {
      if (ids === 0) ids = "";
      let data = state.myVideoListTag
        ? await getMyVideoList({ categoryIds: ids })
        : await getVideoList({ categoryIds: ids, materialType:state.topType });
      state.videoList = data.records;
      state.refreshing = false;
    };

    //加载更多
    const onLoadMore = async () => {
      //曲线解决初始化的时候van-list也会触发onLoad的问题
      if (initGetVideoListTag) {
        state.loading = false;
        return false;
      }
      console.log("===state.loading===", state.loading);
      // console.log("加载更多");
      state.currentPage += 1;
      let data = state.myVideoListTag
        ? await getMyVideoList({
            categoryIds: state.categoryIds,
            current: state.currentPage,
            size: state.size,
          })
        : await getVideoList({
            categoryIds: state.categoryIds,
            current: state.currentPage,
            size: state.size,
            materialType:state.topType,
          });
      if (data) {
        console.log("加载更多---------", data);
      }
      if (data.records.length > 0) {
        state.videoList = state.videoList.concat(data.records);
        state.loading = false;
        console.log("===state.loading===", state.loading);
      } else {
        state.loading = false;
        state.finished = true;
        console.log("===state.loading===", state.loading);
      }
    };

    const onLoadMoreOther = () => {
      state.loading = false;
      console.log("===state.loading===", state.loading);
    };

    //节流加载更多
    const throttleOnLoadMore = throttle(onLoadMore, 1000, onLoadMoreOther);

    //通过ref获取van-tabs
    const vanTabsInstanceRef = ref();

    //底部导航菜单改变监听事件
    const onNavBarChange = (indexName) => {
      console.log(indexName);
      state.navBarActive = indexName;
      console.log("======================",state.navBarActive==="videoList")
      //分类信息恢复默认
      state.categoryIds = null;
      state.box = [];
      resetOnNavTabChange()
      //顶部tab恢复默认
      // state.activeTabIndex = 0
      vanTabsInstanceRef.value.scrollTo({ name: 0 });
      //视频列表恢复默认
      state.videoList = [];
      state.myVideoListTag = indexName == "myVideoList";
      store.commit("SET_MY_VIDEO_LIST_TAG", state.myVideoListTag);
      console.log("===store==", store.getters.myVideoListTag);
      //曲线解决初始化的时候van-list也会触发onLoad的问题
      initGetVideoListTag = true;
      if (indexName == "myVideoList") {
        getMyVideoList({
          size: state.size,
          categoryIds: state.categoryIdss,
        }).then((data) => {
          state.videoList = data.records;
          state.refreshing = false;
          initGetVideoListTag = false;
        });
      } else {
        getVideoList({ size: state.size, categoryIds: state.categoryIds, materialType:state.topType }).then(
          (data) => {
            state.videoList = data.records;
            state.refreshing = false;
            initGetVideoListTag = false;
          }
        );
      }
    };

    //弹出
    const loadSelectType = () => {
      state.showType = !state.showType;
    };

    //更多
    const handleChoice = (it) => {
      if (state.box.includes(it)) {
        state.box.splice(state.box.indexOf(it), 1);
      } else {
        state.box.push(it);
      }
      state.categoryIds =
        state.box.length == 0
          ? ""
          : state.box
              .map((item) => {
                return item.categoryPrimaryId;
              })
              .join(",");
    };
    const loadConfirm = () => {
      console.log("确认");
      console.log(state.box);
      loadSelectType();
      resetOnNavTabChange();
      getInitialVideoList();
    };
    const cancelSelectType = () =>{
      state.showType = false
    }
    //绑定回退事件
    const comback = () => {
      if (route.params.addPoster==='true'){
        // router.push({ path: "/posterDetails" })
        router.push({
          path:'/createPoster',
        })
        return
      }
      if (route.params.fromPoster==='true'){
        // router.push({ path: "/posterDetails" })
        router.push({
          path:'/posterDetails',
        })
      } else {
        // router.push({ path: "/" });
        let param = {
          shareType : "backIndexPage"
        }
        flutterShowToast(param)
      }
    };

    //删除我的视频
    const deleteMyVideo = index => {
      console.log("===删除 index===", index)
      state.videoList.splice(index, 1);
    };

    return {
      state,
      cancelSelectType,
      handleMoreType,
      loadSearchVideo,
      handleCreateVideo,
      onRefresh,
      loadAllType,
      loadSelectType,
      onNavBarChange,
      handleChoice,
      loadConfirm,
      onLoadMore,
      throttleOnLoadMore,
      comback,
      deleteMyVideo,
      vanTabsInstanceRef,
      handleChoiceLvOne,
    };
  },
};
</script>

<style lang="scss">
  .placeHolder {
    height: 46px;
    margin-top: constant(safe-area-inset-top);
    margin-top: env(safe-area-inset-top);
  }
  .twotypeselect{
    display: flex;
    justify-content: space-between;
    .articleType-all {
      overflow-x: hidden;
      .van-tabs__wrap {
        border-bottom: 0.5px solid #f1f3f6;
      }
      .van-tab {
        color: #666666;
        font-size: 12px;
        font-weight: 600;
        padding: 0 20px;
        //flex: auto;
        //   border-bottom: 1px solid #cccccc;
      }
      .van-tab--active {
        color: #323233;
        font-size: 12px;
        font-weight: 600;
      }
    }
    //.tabs-main{
    //  flex: 1;
    //  display: flex;
    //  width: 200px;
    //  overflow-y:hidden;
    //  li{
    //    width: auto;
    //    float: left;
    //  }
    //}
    .more-type{
      width: 100px;
      height: 50px;
      color: #666666;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      line-height: 45px;
      padding: 0 10px;
    }
  }
//.videoType-all {
//  .van-tab {
//    color: #666666;
//    font-size: 12px;
//    font-weight: 600;
//    padding: 0 20px;
//    //   border-bottom: 1px solid #cccccc;
//  }
//  .van-tab--active {
//    color: #323233;
//    font-size: 12px;
//    font-weight: 600;
//  }
//}
.nav_button {
  position: fixed;
  bottom: 0px;
  width: 100vw;
}
.change-color {
  color: #ff6e01;
}

.wrapper {
  position: relative;
  height: 100%;
  .grid-type {
    padding-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    // border-radius: 10px;
    justify-content: space-between;
    >i{
      height: 28px;
      width: 72px;
    }
    .grid-type-item {
      height: 28px;
      width: 72px;
      margin-bottom: 8px;
      border-radius: 5px;
      font-size: 12px;
      line-height: 28px;
      background-color: #f1f3f6;
    }
    .bgColor {
      color: #ffffff;
      background-color: #ffcf23;
    }
  }
  .confirm {
    position: absolute;
    height: 28px;
    width: 72px;
    font-size: 12px;
    line-height: 28px;
    background-color: #ff6e01;
    border-radius: 5px;
    margin-left: 50%;
    left: -36px;
    // transform:translateX(-50%);
    bottom: 20px;
    color: #ffffff;
  }
}

.select-like-type {
  position: absolute;
  height: 365px;
  width: 344px;
  left: 16px;
  top: 88px;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
  background-color: #fff;
  .cancel {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 12px;
    right: 12px;
    background: url("../../assets/icons/x.png") no-repeat;
  }
  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 10px;
  }
}

.header{
  .van-nav-bar__arrow{
    color: #333333;
  }
  .van-nav-bar__title{
    color: #333333;
    font-weight: 600;
  }

  .van-nav-bar__placeholder{
    /*!*iphoneX头部安全区域*!*/
    /*margin-top: constant(safe-area-inset-top);*/
    /*margin-top: env(safe-area-inset-top);*/
  }

  /*.van-tabs--line {*/
  /*  margin-top: constant(safe-area-inset-top);*/
  /*  margin-top: env(safe-area-inset-top);*/
  /*}*/
  .lv-one{
    padding:0 16px;
    font-size: 14px;
    font-weight: 600;
    .lv-one-type{
      display: flex;
      justify-content: space-between;
      height: 38px;
      >div{
        line-height: 38px;
      }
      :nth-child(1){
        border-radius: 4px 0px 0px 4px;
        border: .4px solid #999999;
        flex: 1;
      }
      :nth-child(2){
        flex: 1;
        border-radius: 0px 4px 4px 0px;
        border: .4px solid #999999;
      }
      .bc-color{
        background-color: #FF6E01;
        color: #FFFFFF;
        border: none;
      }
    }
  }

}

.footer-tab-bar {
  height: 60px;

  .add_video {
    height: 36px;
    width: 52px;
    color: #fff;
    line-height: 36px;
    text-align: center;
    border-radius: 8px;
    font-size: 28px;
    position: fixed;
    z-index: 2;
    left: 50%;
    bottom: 7px;
    transform: translateX(-50%);
    background-color: #ff6e01;
  }
}
</style>
<style lang="scss" scoped>
.video-container{
  display: flex;
  flex-direction: column;
  height: 100vh;
  article{
    flex: 1;
    overflow-x: hidden;
  }
}
</style>
